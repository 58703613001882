import { IonButton, IonContent, IonInput, IonItem, IonList } from '@ionic/react'
import { FormEvent, useCallback, useEffect, useReducer } from 'react'
import { useTimerInfo, useTimerMachine } from '../../../hooks/useTimerMachine'
import { TimerConfig } from '../../../models/timerConfig'

const reducer = (
  state: TimerConfig,
  action: { type: 'REPLACE'; payload: TimerConfig } | { type: keyof TimerConfig; payload: number }
) => {
  switch (action.type) {
    case 'REPLACE':
      return action.payload as TimerConfig
    default:
      return {
        ...state,
        [action.type]: action.payload,
      }
  }
}

export type TimerSettingFormProps = {
  didSubmit: () => void
}

export const TimerSettingForm = ({ didSubmit }: TimerSettingFormProps) => {
  const { saveConfig } = useTimerMachine()
  const { timerConfig } = useTimerInfo()
  const [config, dispatch] = useReducer(reducer, timerConfig)

  useEffect(() => {
    dispatch({ type: 'REPLACE', payload: timerConfig })
  }, [timerConfig])

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      saveConfig(config)
      didSubmit()
    },
    [config, didSubmit, saveConfig]
  )
  return (
    <IonContent>
      <div className="container my-4 mx-auto">
        <form onSubmit={handleSubmit}>
          <IonList>
            <IonItem>
              <IonInput
                type="number"
                label="Focus time"
                value={config.focus_minutes}
                min={5}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'focus_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                type="number"
                label="Interval time"
                value={config.interval_minutes}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'interval_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                type="number"
                label="Rest time"
                value={config.rest_minutes}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'rest_minutes', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                type="number"
                label="Reps"
                value={config.reps}
                placeholder="Enter Number"
                onIonChange={(e) => dispatch({ type: 'reps', payload: Number(e.detail.value) })}
              ></IonInput>
            </IonItem>
          </IonList>
          <div className="mt-4">
            <IonButton color="primary" size="default" type="submit">
              Save
            </IonButton>
          </div>
        </form>
      </div>
    </IonContent>
  )
}

export default TimerSettingForm
