import { BELL_SOUND_ID, SoundMap, TICK_SOUND_ID } from '../../consts/sound'
import { useMeTimezone } from '../../hooks/useMe'
import { usePlatform } from '../../hooks/usePlatform'
import { useTimerInfo } from '../../hooks/useTimerMachine'
import { REST } from '../../machines/timerStateKey'
import { ContinueTimerActionSheet } from './action/ContinueTimerActionSheet'
import { CookieConsentBanner } from './utils/CookieConsentBannar'
import { TimerAutomation } from './utils/TimerAutomation'

export const AppModule = () => {
  const isNative = usePlatform('native')
  useMeTimezone()
  const { state, timer } = useTimerInfo()

  return (
    <>
      <audio id={BELL_SOUND_ID} src={SoundMap['bell']} />
      <audio id={TICK_SOUND_ID} src={SoundMap['tick']} />
      <TimerAutomation />
      <ContinueTimerActionSheet open={Boolean(state === REST && timer?.is_finished)} />
      {isNative ? null : <CookieConsentBanner />}
    </>
  )
}
