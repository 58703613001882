import clsx from 'clsx'
import { secondsToMinutes } from 'date-fns'
import { Suspense, useState } from 'react'
import { INTERVAL, REST, STOPPED, WORKING } from '../../../machines/timerStateKey'
import { timerFormat } from '../../../utils/format'
import { padLeftStrng } from '../../../utils/util'
import { TimerInfoState } from '../utils/TimerInfoState'
import { TimerTickInfoState } from '../utils/TimerTickInfoState'

export const TimerInfo = () => {
  const [isExpand, setIsExpand] = useState(false)
  return (
    <Suspense fallback={<></>}>
      <TimerTickInfoState>
        {({ state, nextSeconds, timer }) => {
          if (!timer || timer.is_finished) return null
          const minutes = secondsToMinutes(nextSeconds)
          const seconds = Math.trunc(nextSeconds - minutes * 60)

          return (
            <div>
              <button
                className={clsx('p-2 my-2 min-w-[10rem] text-black bg-white dark:bg-slate-100 rounded shadow')}
                onClick={() => setIsExpand((prev) => !prev)}
              >
                <div className="flex flex-col justify-center items-center">
                  <TimerInfoState>
                    {({ timer, timerConfig }) =>
                      timer && (
                        <>
                          {timerConfig && isExpand && (
                            <>
                              <p className="text-sm">
                                {timerFormat(timer.start_time)} ~ {timerFormat(timer.end_time)}
                              </p>
                              <p className="text-sm">
                                Reps: {timer.reps}/{timerConfig.reps}
                              </p>
                            </>
                          )}
                        </>
                      )
                    }
                  </TimerInfoState>
                  {state && (
                    <p className="text-lg">
                      {{ [WORKING]: '🚀', [INTERVAL]: '⏳', [REST]: '🏖️', [STOPPED]: undefined }[state]}{' '}
                      {`${padLeftStrng(minutes)}:${padLeftStrng(seconds)}`}
                    </p>
                  )}
                </div>
              </button>
            </div>
          )
        }}
      </TimerTickInfoState>
    </Suspense>
  )
}
