import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { playOutline, settingsSharp, stopOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTimerInfo, useTimerMachine } from '../../../hooks/useTimerMachine'
import { STOPPED } from '../../../machines/timerStateKey'
import SettingForm from '../form/TimerSettingForm'

export const TimerActions = () => {
  const { state } = useTimerInfo()
  const { startTimer, stopTimer } = useTimerMachine()
  const [modalOpen, setModalOpen] = useState(false)

  const handleStart = useCallback(async () => {
    startTimer()
  }, [startTimer])

  const handleStop = useCallback(async () => {
    stopTimer()
  }, [stopTimer])

  return (
    <>
      <div className="flex">
        {state !== STOPPED ? (
          <IonButton color="tertiary" onClick={handleStop} title="Stop">
            <IonIcon slot="start" icon={stopOutline} />
            Stop
          </IonButton>
        ) : (
          <>
            <IonButton fill="clear" color="medium" onClick={() => setModalOpen(true)} title="Timer Setting">
              <IonIcon icon={settingsSharp} slot="icon-only" />
            </IonButton>
            <IonButton color="success" onClick={handleStart} title="Play">
              <IonIcon slot="start" icon={playOutline} />
              Play
            </IonButton>
          </>
        )}
      </div>
      <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Timer Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setModalOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <SettingForm didSubmit={() => setModalOpen(false)} />
        </IonContent>
      </IonModal>
    </>
  )
}
