import { nanoid } from 'nanoid'
import { choose, raise, assign } from 'xstate'
import { ONE_MINUTES_MS } from '../consts/timer'
import { Timer } from '../models/timer'
import { TimerConfig } from '../models/timerConfig'
import { storage, StorageEnum } from '../storage/default'
import { TimerActorEvent, TimerActorMachineContext } from './TimerActorTypes'

export const timerActorActions = {
  chooseState: choose([
    {
      // REST時にCONTINUEして次のタイマーをセットした場合にのみRESTに戻る
      // 何もせず is_finished == true のままの時はSTOPPEDに移動する
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) =>
        Boolean(
          context.timer &&
            ((context.timer.reps === 1 && Date.now() < context.timer.start_time) ||
              (context.timer.is_finished &&
                context.timer.reps === context.timerConfig.reps &&
                Date.now() < context.timer.end_time + context.timerConfig.rest_minutes * ONE_MINUTES_MS))
        ),
      actions: raise({ type: 'REST' }),
    },
    {
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) => !context.timer || context.timer.is_finished,
      actions: raise({ type: 'STOPPED' }),
    },
    {
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) =>
        Boolean(context.timer && context.timer.reps !== 1 && Date.now() < context.timer.start_time),
      actions: raise({ type: 'INTERVAL' }),
    },
    {
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) =>
        Boolean(context.timer && !context.timer.is_finished),
      actions: raise({ type: 'WORKING' }),
    },
    {
      actions: raise({ type: 'STOPPED' }),
    },
  ]),
  chooseNext: choose([
    {
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) =>
        Boolean(context.timer && context.timer.reps === context.timerConfig.reps),
      actions: raise({ type: 'STOP' }),
    },
    {
      cond: (context: TimerActorMachineContext, _event: TimerActorEvent) =>
        Boolean(context.timer && Date.now() < context.timer.end_time + 10 * ONE_MINUTES_MS),
      actions: raise({ type: 'CONTINUE' }),
    },
    {
      actions: raise({ type: 'STOP' }),
    },
  ]),
  assignTimerAndConfig: assign<
    TimerActorMachineContext,
    { type: string; data?: { timer?: Timer; timerConfig: TimerConfig } }
  >({
    timer: (_, event) => {
      return event.data?.timer
    },
    timerConfig: (_, event) => {
      return event.data?.timerConfig as TimerConfig
    },
  }),
  assignTimer: assign<TimerActorMachineContext, { type: string; data?: Timer }>({
    timer: (_, event) => {
      return event.data
    },
  }),
  assignError: assign<TimerActorMachineContext, { type: string; data: Error }>({
    error: (_, event) => event.data,
  }),
  assignTimerConfig: assign<TimerActorMachineContext, { type: string; data: Omit<TimerConfig, 'id'> }>({
    timer: () => {
      return undefined
    },
    timerConfig: (_, event) => {
      return {
        id: nanoid(),
        ...event.data,
      }
    },
  }),
  saveTimerAndConfig: async (context: TimerActorMachineContext) => {
    await storage.set(StorageEnum.TIMER_INFO, {
      timer: context.timer,
      timerConfig: context.timerConfig,
    })
  },
  assignNextTaskId: assign<TimerActorMachineContext, { type: string; taskId?: string }>({
    nextTaskId: (_, event) => event.taskId,
  }),
}
