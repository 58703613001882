import { createContext, PropsWithChildren, useState } from 'react'
import { storage, StorageEnum } from '../storage/default'

type ThemeValue = 'light' | 'dark' | undefined

export const ThemeContext = createContext<{ theme: ThemeValue; updateTheme: (theme: ThemeValue) => void }>({
  theme: undefined as ThemeValue,
  updateTheme: (theme: ThemeValue) => undefined,
})

interface Response<T> {
  status: 'success' | 'pending' | 'error'
  data: T | null
}

const res: Response<unknown> = { status: 'pending', data: null }

/**
 * This is our suspender function
 * that throws promise if it is not fulfilled yet
 */
export const suspend = <T,>(fn: () => Promise<T>): T => {
  const handler = (response: Response<T>) => {
    /**
     * suspender is the promise we will throw
     * so react can re-render when it is fulfilled
     */
    const suspender = fn().then(
      (res) => {
        response.status = 'success'
        response.data = res
      },
      (error) => {
        response.status = 'error'
        response.data = error
      }
    )

    switch (response.status) {
      case 'pending':
        throw suspender
      case 'error':
        // eslint-disable-next-line no-throw-literal
        throw response.data as T
      default:
        return response.data as T
    }
  }
  return handler(res as Response<T>)
}

const themePromise = storage.get(StorageEnum.CONFIG_THEME) as Promise<ThemeValue>

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const initialTheme = suspend(() => themePromise)
  const [theme, setTheme] = useState(initialTheme)
  const updateTheme = (theme: ThemeValue) => {
    storage.set(StorageEnum.CONFIG_THEME, theme)
    setTheme(theme)
  }

  return <ThemeContext.Provider value={{ theme, updateTheme }}>{children}</ThemeContext.Provider>
}
