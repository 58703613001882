import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { configure } from './utils/auth'

import TagManager from 'react-gtm-module'
import { GTM_ID } from './consts/analytics'
import { AuthContentScriptProxy } from './utils/content-script-proxy'

configure()

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { inspect } = require('@xstate/inspect')
  inspect({
    // options
    // url: 'https://stately.ai/viz?inspect', // (default)
    iframe: false,
  })
}

if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  })
}

const authProxy = new AuthContentScriptProxy()
authProxy.init()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // WATCH ME <React.StrictMode> is disabled because of dev server peding issue.
  <>
    <App />
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.unregister().then(() => {
        window.location.reload()
      })
    }
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
