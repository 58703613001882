import type { TimerViewport } from '../../viewport'
import { HourHand, MinuteHand, SecondHand } from '../atoms/normal/index'

export interface ClockHandsProps {
  tick: number
  viewport: TimerViewport
  color: string
  radius: number
}

export default function ClockHands({ tick, ...rest }: ClockHandsProps) {
  return (
    <g>
      <SecondHand {...rest} time={tick} />
      <MinuteHand {...rest} time={tick} />
      <HourHand {...rest} time={tick} />
    </g>
  )
}
