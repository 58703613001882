export const INITIATE = 'initiate'
export const CHOOSE_STATE = 'choose_state'
export const CHOOSE_NEXT = 'choose_next'
export const STOPPED = 'stopped'
export const WORKING = 'working'
export const INTERVAL = 'interval'
export const REST = 'rest'
export const ERROR = 'error'

export const CALL_SYNC = 'call_sync'
export const CALL_START = 'call_start'
export const CALL_FINISH = 'call_finish'
export const CALL_FINISH_REST = 'call_finish_rest'
export const CALL_CONTINUE = 'call_continue'
export const CALL_CONTINUE_REST = 'call_continue_rest'
export const CALL_SAVE_CONFIG = 'call_save_config'
