import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * ISO 8601 time without timezone.
   * Allows for the nanosecond precision and optional leap second representation.
   * Format: %H:%M:%S%.f
   *
   * # Examples
   *
   * * `08:59:60.123`
   */
  Date: string;
  /**
   * Implement the DateTime<Utc> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: string;
  TimeZone: string;
};

export type CreateTaskInput = {
  color_code: Scalars['String'];
  color_name: Scalars['String'];
  session_count: Scalars['Int'];
  task_name: Scalars['String'];
};

export type CreateUserInput = {
  timezone: Scalars['TimeZone'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Check timer as valid */
  checkTimer: Timer;
  createTask: Scalars['Boolean'];
  /**
   * Start timer with creating timer data.
   *
   * If current end_time not passed, just return current timer.
   */
  createTimer: Timer;
  createTimerConfig: TimerConfig;
  createUser: User;
  /**
   * Delete today's task.
   * If task is not of today, then do nothing and return false.
   */
  deleteTask: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  /**
   * Finish existing timer
   * Return true when successfully finished - flagged is_finished true.
   * If timer not exists or is_valid is false - therefore is_finished false, return false.
   */
  finishTimer: Scalars['Boolean'];
  generateTaskQueue: Scalars['Boolean'];
  /** Sort task items */
  sortTask: Scalars['Boolean'];
  /** Sort task queue */
  sortTaskQueue: Scalars['Boolean'];
  updateTask: Scalars['Boolean'];
  updateUser: User;
};


export type MutationCheckTimerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTimerArgs = {
  timer: TimerInput;
};


export type MutationCreateTimerConfigArgs = {
  timerConfig: TimerConfigInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationFinishTimerArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateTaskQueueArgs = {
  items: Array<Scalars['ID']>;
};


export type MutationSortTaskArgs = {
  input: SortTaskInput;
};


export type MutationSortTaskQueueArgs = {
  input: SortTaskQueueInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  task: Task;
  timer?: Maybe<Timer>;
  timerConfig: TimerConfig;
  timerHistory: Array<Timer>;
};


export type QueryTimerHistoryArgs = {
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
};

export type SortTaskInput = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type SortTaskQueueInput = {
  from?: InputMaybe<Scalars['Int']>;
  sort_type: TaskSortType;
  to?: InputMaybe<Scalars['Int']>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  items: Array<TaskItem>;
  queue_date: Scalars['Date'];
  sort_type: TaskSortType;
  task_finished: Array<Scalars['ID']>;
  task_queue: Array<Scalars['ID']>;
  version: Scalars['Int'];
};

export type TaskItem = {
  __typename?: 'TaskItem';
  color_code: Scalars['String'];
  color_name: Scalars['String'];
  id: Scalars['ID'];
  session_count: Scalars['Int'];
  task_name: Scalars['String'];
};

export enum TaskSortType {
  Manual = 'Manual',
  RoundRobin = 'RoundRobin',
  TopToBottom = 'TopToBottom'
}

export type Timer = {
  __typename?: 'Timer';
  config_id: Scalars['ID'];
  date: Scalars['Date'];
  end_time: Scalars['DateTime'];
  id: Scalars['ID'];
  is_finished: Scalars['Boolean'];
  is_valid: Scalars['Boolean'];
  prev_id?: Maybe<Scalars['ID']>;
  reps: Scalars['Int'];
  start_time: Scalars['DateTime'];
  task?: Maybe<TaskItem>;
};

export type TimerConfig = {
  __typename?: 'TimerConfig';
  focus_minutes: Scalars['Int'];
  id: Scalars['ID'];
  interval_minutes: Scalars['Int'];
  reps: Scalars['Int'];
  rest_minutes: Scalars['Int'];
};

export type TimerConfigInput = {
  focus_minutes: Scalars['Int'];
  interval_minutes: Scalars['Int'];
  reps: Scalars['Int'];
  rest_minutes: Scalars['Int'];
};

export type TimerInput = {
  config_id: Scalars['ID'];
  end_time: Scalars['DateTime'];
  prev_id?: InputMaybe<Scalars['ID']>;
  reps: Scalars['Int'];
  start_time: Scalars['DateTime'];
  task_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateTaskInput = {
  color_code?: InputMaybe<Scalars['String']>;
  color_name?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  session_count?: InputMaybe<Scalars['Int']>;
  task_name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  timezone?: InputMaybe<Scalars['TimeZone']>;
};

export type User = {
  __typename?: 'User';
  sub: Scalars['ID'];
  timezone?: Maybe<Scalars['TimeZone']>;
  user_id: Scalars['ID'];
};

export type GetTimerAndConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimerAndConfigQuery = { __typename?: 'Query', timer?: { __typename?: 'Timer', id: string, start_time: string, end_time: string, date: string, reps: number, prev_id?: string | null, config_id: string, is_finished: boolean, is_valid: boolean, task?: { __typename?: 'TaskItem', id: string, task_name: string, color_name: string, color_code: string, session_count: number } | null } | null, timerConfig: { __typename?: 'TimerConfig', id: string, focus_minutes: number, interval_minutes: number, reps: number, rest_minutes: number } };

export type GetTaskQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, version: number, sort_type: TaskSortType, queue_date: string, task_queue: Array<string>, task_finished: Array<string>, items: Array<{ __typename?: 'TaskItem', color_code: string, color_name: string, id: string, session_count: number, task_name: string }> } };

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: boolean };

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskMutation = { __typename?: 'Mutation', deleteTask: boolean };

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: boolean };

export type SortTaskMutationVariables = Exact<{
  input: SortTaskInput;
}>;


export type SortTaskMutation = { __typename?: 'Mutation', sortTask: boolean };

export type GenerateTaskQueueMutationVariables = Exact<{
  items: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GenerateTaskQueueMutation = { __typename?: 'Mutation', generateTaskQueue: boolean };

export type SortTaskQueueMutationVariables = Exact<{
  input: SortTaskQueueInput;
}>;


export type SortTaskQueueMutation = { __typename?: 'Mutation', sortTaskQueue: boolean };

export type CurrentTimerQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentTimerQuery = { __typename?: 'Query', timer?: { __typename?: 'Timer', id: string, start_time: string, end_time: string, date: string, reps: number, prev_id?: string | null, config_id: string, is_finished: boolean, is_valid: boolean, task?: { __typename?: 'TaskItem', id: string, task_name: string, color_name: string, color_code: string, session_count: number } | null } | null };

export type CreateTimerMutationMutationVariables = Exact<{
  input: TimerInput;
}>;


export type CreateTimerMutationMutation = { __typename?: 'Mutation', createTimer: { __typename?: 'Timer', id: string, start_time: string, end_time: string, date: string, reps: number, prev_id?: string | null, config_id: string, is_finished: boolean, is_valid: boolean, task?: { __typename?: 'TaskItem', id: string, task_name: string, color_name: string, color_code: string, session_count: number } | null } };

export type FinishTimerMutationMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FinishTimerMutationMutation = { __typename?: 'Mutation', finishTimer: boolean };

export type CheckTimerMutationMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type CheckTimerMutationMutation = { __typename?: 'Mutation', checkTimer: { __typename?: 'Timer', id: string, start_time: string, end_time: string, date: string, reps: number, prev_id?: string | null, config_id: string, is_finished: boolean, is_valid: boolean, task?: { __typename?: 'TaskItem', id: string, task_name: string, color_name: string, color_code: string, session_count: number } | null } };

export type MyTimerConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTimerConfigQuery = { __typename?: 'Query', timerConfig: { __typename?: 'TimerConfig', id: string, focus_minutes: number, interval_minutes: number, reps: number, rest_minutes: number } };

export type MyTimerConfigMutationMutationVariables = Exact<{
  input: TimerConfigInput;
}>;


export type MyTimerConfigMutationMutation = { __typename?: 'Mutation', createTimerConfig: { __typename?: 'TimerConfig', id: string, interval_minutes: number, focus_minutes: number, reps: number, rest_minutes: number } };

export type SearchTimerHistoryQueryQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
}>;


export type SearchTimerHistoryQueryQuery = { __typename?: 'Query', timerHistory: Array<{ __typename?: 'Timer', id: string, reps: number, start_time: string, end_time: string, is_finished: boolean, prev_id?: string | null, config_id: string }> };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'User', sub: string, user_id: string, timezone?: string | null } };

export type CreateUserMutationMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutationMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', sub: string, user_id: string, timezone?: string | null } };

export type DeleteUserMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutationMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type UpdateUserMutationMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutationMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', sub: string, user_id: string, timezone?: string | null } };


export const GetTimerAndConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTimerAndConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}},{"kind":"Field","name":{"kind":"Name","value":"task"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"task_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_code"}},{"kind":"Field","name":{"kind":"Name","value":"session_count"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"timerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<GetTimerAndConfigQuery, GetTimerAndConfigQueryVariables>;
export const GetTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTask"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"task"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"version"}},{"kind":"Field","name":{"kind":"Name","value":"sort_type"}},{"kind":"Field","name":{"kind":"Name","value":"queue_date"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"color_code"}},{"kind":"Field","name":{"kind":"Name","value":"color_name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"session_count"}},{"kind":"Field","name":{"kind":"Name","value":"task_name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"task_queue"}},{"kind":"Field","name":{"kind":"Name","value":"task_finished"}}]}}]}}]} as unknown as DocumentNode<GetTaskQuery, GetTaskQueryVariables>;
export const CreateTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTaskInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const DeleteTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const UpdateTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateTaskInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const SortTaskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SortTask"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SortTaskInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sortTask"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<SortTaskMutation, SortTaskMutationVariables>;
export const GenerateTaskQueueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateTaskQueue"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"items"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateTaskQueue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"items"},"value":{"kind":"Variable","name":{"kind":"Name","value":"items"}}}]}]}}]} as unknown as DocumentNode<GenerateTaskQueueMutation, GenerateTaskQueueMutationVariables>;
export const SortTaskQueueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SortTaskQueue"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SortTaskQueueInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sortTaskQueue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<SortTaskQueueMutation, SortTaskQueueMutationVariables>;
export const CurrentTimerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentTimer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}},{"kind":"Field","name":{"kind":"Name","value":"task"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"task_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_code"}},{"kind":"Field","name":{"kind":"Name","value":"session_count"}}]}}]}}]}}]} as unknown as DocumentNode<CurrentTimerQuery, CurrentTimerQueryVariables>;
export const CreateTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TimerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"timer"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}},{"kind":"Field","name":{"kind":"Name","value":"task"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"task_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_code"}},{"kind":"Field","name":{"kind":"Name","value":"session_count"}}]}}]}}]}}]} as unknown as DocumentNode<CreateTimerMutationMutation, CreateTimerMutationMutationVariables>;
export const FinishTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FinishTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finishTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<FinishTimerMutationMutation, FinishTimerMutationMutationVariables>;
export const CheckTimerMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CheckTimerMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkTimer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"is_valid"}},{"kind":"Field","name":{"kind":"Name","value":"task"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"task_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_name"}},{"kind":"Field","name":{"kind":"Name","value":"color_code"}},{"kind":"Field","name":{"kind":"Name","value":"session_count"}}]}}]}}]}}]} as unknown as DocumentNode<CheckTimerMutationMutation, CheckTimerMutationMutationVariables>;
export const MyTimerConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyTimerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timerConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<MyTimerConfigQuery, MyTimerConfigQueryVariables>;
export const MyTimerConfigMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MyTimerConfigMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TimerConfigInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTimerConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"timerConfig"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"interval_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"focus_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"rest_minutes"}}]}}]}}]} as unknown as DocumentNode<MyTimerConfigMutationMutation, MyTimerConfigMutationMutationVariables>;
export const SearchTimerHistoryQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchTimerHistoryQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"from"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"to"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timerHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"from"},"value":{"kind":"Variable","name":{"kind":"Name","value":"from"}}},{"kind":"Argument","name":{"kind":"Name","value":"to"},"value":{"kind":"Variable","name":{"kind":"Name","value":"to"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reps"}},{"kind":"Field","name":{"kind":"Name","value":"start_time"}},{"kind":"Field","name":{"kind":"Name","value":"end_time"}},{"kind":"Field","name":{"kind":"Name","value":"is_finished"}},{"kind":"Field","name":{"kind":"Name","value":"prev_id"}},{"kind":"Field","name":{"kind":"Name","value":"config_id"}}]}}]}}]} as unknown as DocumentNode<SearchTimerHistoryQueryQuery, SearchTimerHistoryQueryQueryVariables>;
export const GetMeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMe"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sub"}},{"kind":"Field","name":{"kind":"Name","value":"user_id"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}}]}}]}}]} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const CreateUserMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sub"}},{"kind":"Field","name":{"kind":"Name","value":"user_id"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}}]}}]}}]} as unknown as DocumentNode<CreateUserMutationMutation, CreateUserMutationMutationVariables>;
export const DeleteUserMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserMutation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUser"}}]}}]} as unknown as DocumentNode<DeleteUserMutationMutation, DeleteUserMutationMutationVariables>;
export const UpdateUserMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sub"}},{"kind":"Field","name":{"kind":"Name","value":"user_id"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}}]}}]}}]} as unknown as DocumentNode<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>;