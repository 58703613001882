import secondsToMinutes from 'date-fns/secondsToMinutes'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { APP_TITLE } from '../../../consts/envs'
import { usePlatform } from '../../../hooks/usePlatform'
import { INTERVAL, REST, STOPPED, WORKING } from '../../../machines/timerStateKey'
import { padLeftStrng } from '../../../utils/util'
import { TimerTickInfoState } from '../utils/TimerTickInfoState'

export type TimerActionHeadProps = {
  title?: string
}

export const TimerActionHead = ({ title }: TimerActionHeadProps) => {
  const isNative = usePlatform('native')
  if (isNative) {
    return null
  }

  return (
    <TimerTickInfoState>
      {({ state, nextSeconds, timer }) => (
        <Fragment key={nextSeconds}>
          <Helmet defer={false} titleTemplate={`%s - ${APP_TITLE}`} defaultTitle={APP_TITLE}>
            {title ? (
              <Helmet defer={false} titleTemplate={`%s - ${APP_TITLE}`} defaultTitle={APP_TITLE}>
                <title>{title}</title>
              </Helmet>
            ) : (
              timer &&
              !timer.is_finished && (
                <title>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    { [WORKING]: '🚀', [INTERVAL]: '⏳', [REST]: '🏖️', [STOPPED]: '' }[state!] ?? ''
                  }{' '}
                  {`${padLeftStrng(secondsToMinutes(nextSeconds))}:${padLeftStrng(
                    Math.trunc(nextSeconds - secondsToMinutes(nextSeconds) * 60)
                  )}`}
                </title>
              )
            )}
          </Helmet>
        </Fragment>
      )}
    </TimerTickInfoState>
  )
}
