import { IonActionSheet } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { useTimerMachine } from '../../../hooks/useTimerMachine'

export type ContinueTimerActionSheetProps = {
  open: boolean
}

export const ContinueTimerActionSheet = ({ open }: ContinueTimerActionSheetProps) => {
  const { continueTimer } = useTimerMachine()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => setIsOpen(open), [open])

  const handleCancel = useCallback(async () => {
    setIsOpen(false)
  }, [])

  const handleContinue = useCallback(async () => {
    continueTimer()
  }, [continueTimer])

  return (
    <IonActionSheet
      isOpen={isOpen}
      header="Would you like to continue?"
      backdropDismiss={false}
      onDidDismiss={() => setIsOpen(false)}
      buttons={[
        {
          text: 'Continue',
          handler: handleContinue,
        },
        {
          text: 'Cancel',
          role: 'destructive',
          handler: handleCancel,
        },
      ]}
    ></IonActionSheet>
  )
}
