import { useCallback, useContext, useEffect } from 'react'
import { BackgroundFetch } from '@awesome-cordova-plugins/background-fetch'
import { useIdle } from 'react-use'
import { TimerMachineContext } from '../contexts/TimerMachineContext'
import { App } from '@capacitor/app'
import { isPlatform } from '@ionic/react'
import { logger } from '../utils/logger'
import { TimerConfig } from '../models/timerConfig'
import { useActor, useSelector } from '@xstate/react'
import { WORKING } from '../machines/timerStateKey'
import { useQuery } from 'urql'
import { GetTaskDocument } from '../generated'
import { useAuthenticator } from '@aws-amplify/ui-react'

const isNative = isPlatform('capacitor')

export const useTimerMachine = () => {
  const { authStatus } = useAuthenticator((context) => [context.user])
  const [taskQuery] = useQuery({ query: GetTaskDocument, pause: authStatus !== 'authenticated' })
  const timerMachineService = useContext(TimerMachineContext)
  const [, send] = useActor(timerMachineService)

  const taskId = taskQuery.data?.task.task_queue?.[0]

  useEffect(() => {
    send({ type: 'UPDATE_NEXT_TASK_ID', taskId })
  }, [send, taskId])

  const startTimer = useCallback(() => {
    send({ type: 'START' })
  }, [send])

  const stopTimer = useCallback(() => {
    send('STOP')
  }, [send])

  const continueTimer = useCallback(() => {
    send({ type: 'CONTINUE' })
  }, [send])

  const checkTimer = useCallback(() => {
    send('CHECK')
  }, [send])

  const syncTimer = useCallback(() => {
    send('SYNC')
  }, [send])

  const saveConfig = useCallback(
    (timerConfig: Omit<TimerConfig, 'id'>) => {
      send({ type: 'SAVE_CONFIG', timerConfig })
    },
    [send]
  )

  return {
    startTimer,
    stopTimer,
    continueTimer,
    checkTimer,
    syncTimer,
    saveConfig,
  }
}

export const useTimerInfo = () => {
  const timerMachineService = useContext(TimerMachineContext)
  const state = useSelector(timerMachineService, (state) => state.value)
  const { timer, timerConfig } = useSelector(timerMachineService, (state) => ({
    timer: state.context.timer,
    timerConfig: state.context.timerConfig,
  }))

  return {
    state,
    timer,
    timerConfig,
  }
}

export const useTimerAutomation = () => {
  const { syncTimer, continueTimer, checkTimer } = useTimerMachine()
  const { state } = useTimerInfo()

  useEffect(() => {
    if (isNative) {
      BackgroundFetch.configure({ stopOnTerminate: true })
        .then(
          (taskId) => {
            console.log('[BackgroundFetch] EVENT:', taskId)
            syncTimer()
            checkTimer()
            continueTimer()
            console.log('[BackgroundFetch] work complete', taskId)
            BackgroundFetch.finish(taskId)
          },
          (taskId) => {
            // The OS has signalled that your remaining background-time has expired.
            // You must immediately complete your work and signal #finish.
            console.log('[BackgroundFetch] TIMEOUT:', taskId)
            // [REQUIRED] Signal to the OS that your work is complete.
            BackgroundFetch.finish(taskId)
          }
        )
        .catch((err) => console.debug(err))
    }
  }, [checkTimer, continueTimer, syncTimer])

  useEffect(() => {
    if (state !== WORKING) return

    const getRandomSec = () => 45 + 60 * Math.random()
    let timeoutId: number
    function timeoutedCheckTimer() {
      checkTimer()
      const sec = getRandomSec()
      logger.debug(`Check timer after ${sec}sec`)
      timeoutId = window.setTimeout(timeoutedCheckTimer, sec * 1000)
    }
    timeoutedCheckTimer()
    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [checkTimer, state])
}

const IDLE_REFRESH_TIME = 3 * 60e3 // 60e3 = 1min

export const useTimerSyncAutomation = () => {
  const isIdle = useIdle(IDLE_REFRESH_TIME)
  const { syncTimer } = useTimerMachine()

  useEffect(() => {
    if (!isIdle) {
      syncTimer()
    }
  }, [isIdle, syncTimer])

  useEffect(() => {
    if (!isNative) {
      return
    }

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        syncTimer()
      }
    })
  }, [syncTimer])
}
