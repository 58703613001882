import { FC } from 'react'
import type { TimerViewport } from '../../../viewport'
import { NormalGauge } from '../../atoms/frames/normal/NormalGauge'
import { TimerTickInfoState } from '../utils/TimerTickInfoState'

const FOCUS_COLOR = '#00ff00'
const RELAX_COLOR = '#22d6ff'

export interface TimerGaugeProps {
  viewport: TimerViewport
  radius: number
}

export const TimerGauge: FC<TimerGaugeProps> = ({ viewport, radius }) => {
  return (
    <TimerTickInfoState>
      {({ tick, start_point, end_point }) => (
        <>
          {tick < start_point && (
            <g color={RELAX_COLOR}>
              <NormalGauge viewport={viewport} radius={radius} start={tick} end={start_point} />
            </g>
          )}
          {start_point < end_point && (
            <g color={FOCUS_COLOR}>
              <NormalGauge viewport={viewport} radius={radius} start={start_point} end={end_point} />
            </g>
          )}
        </>
      )}
    </TimerTickInfoState>
  )
}
