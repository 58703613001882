import { createContext, PropsWithChildren } from 'react'
import { localTimerMachine } from '../machines/localTimerMachine'
import { useInterpret } from '@xstate/react'
import { InterpreterFrom } from 'xstate'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { syncTimerMachine } from '../machines/syncTimerMachine'

export const TimerMachineContext = createContext(undefined as unknown as InterpreterFrom<typeof localTimerMachine>)

export const TimerMachineProvider = ({ children }: PropsWithChildren) => {
  const { authStatus } = useAuthenticator((context) => [context.user])

  if (authStatus === 'authenticated') {
    return <SyncMachine>{children}</SyncMachine>
  }

  return <LocalMachine>{children}</LocalMachine>
}

const LocalMachine = ({ children }: PropsWithChildren) => {
  const localMachine = useInterpret(localTimerMachine, { devTools: true })
  return <TimerMachineContext.Provider value={localMachine}>{children}</TimerMachineContext.Provider>
}

const SyncMachine = ({ children }: PropsWithChildren) => {
  const syncMachine = useInterpret(syncTimerMachine, { devTools: true })
  return <TimerMachineContext.Provider value={syncMachine}>{children}</TimerMachineContext.Provider>
}
