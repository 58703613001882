import { assign, choose, createMachine, forwardTo, log, sendParent, sendTo, spawn, StateMachine } from 'xstate'
import { BELL_SOUND_ID, TICK_SOUND_ID } from '../consts/sound'
import { ONE_MINUTES_MS } from '../consts/timer'
import { Timer } from '../models/timer'
import { TimerConfig } from '../models/timerConfig'
import { createSoundMachine } from './soundMachine'
import { timerActorActions } from './timerActions'
import { TimerActorEvent, TimerActorMachineContext } from './TimerActorTypes'
import { TimerMachineContext } from './timerContext'
import { TimerEvent } from './timerEvent'
import { CHECK, CONTINUE, FINISH, SAVE_CONFIG, START, SYNC } from './timerServiceKey'
import { CHOOSE_NEXT, CHOOSE_STATE, ERROR, INITIATE, INTERVAL, REST, STOPPED, WORKING } from './timerStateKey'

export type TimerActorMachineService = {
  [SYNC]: (_context: TimerActorMachineContext, _event: unknown) => Promise<{ timer?: Timer; timerConfig: TimerConfig }>
  [START]: (context: TimerActorMachineContext, event: TimerActorEvent) => Promise<Timer | undefined>
  [FINISH]: (context: TimerActorMachineContext, _event: unknown) => Promise<Timer | undefined>
  [CHECK]: (context: TimerActorMachineContext, _event: unknown) => Promise<Timer | undefined>
  [CONTINUE]: (context: TimerActorMachineContext, event: TimerActorEvent) => Promise<Timer | undefined>
  [SAVE_CONFIG]: (_context: TimerActorMachineContext, event: TimerActorEvent) => Promise<TimerConfig>
}

export const createTimerActorMachine = (id: string, services: TimerActorMachineService) => {
  return createMachine(
    {
      predictableActionArguments: true,
      id,
      initial: 'waiting',
      schema: {
        context: {} as TimerActorMachineContext,
        events: {} as TimerActorEvent,
      },
      context: {} as TimerActorMachineContext,
      on: {
        SYNC: SYNC,
        START: START,
        STOP: FINISH,
        CHECK: CHECK,
        CHOOSE_NEXT: CHOOSE_NEXT,
        CONTINUE: CONTINUE,
        SAVE_CONFIG: SAVE_CONFIG,
        UPDATE_NEXT_TASK_ID: {
          actions: timerActorActions.assignNextTaskId,
        },
      },
      states: {
        waiting: {},
        [ERROR]: {
          entry: [log((context, event) => `error: ${context.error}, event: ${event}`, 'Finish label')],
          after: {
            10000: {
              target: SYNC,
            },
          },
          on: {
            REINITIATE: SYNC,
          },
        },
        [SYNC]: {
          invoke: {
            src: SYNC,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimerAndConfig],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
        [CHOOSE_STATE]: {
          entry: [
            timerActorActions.saveTimerAndConfig,
            sendParent({ type: 'REMOTE.UPDATE_STATE' }),
            timerActorActions.chooseState,
          ],
          on: {
            STOPPED: { actions: sendParent({ type: 'REMOTE.STOPPED' }) },
            WORKING: { actions: sendParent({ type: 'REMOTE.WORKING' }) },
            INTERVAL: { actions: sendParent({ type: 'REMOTE.INTERVAL' }) },
            REST: { actions: sendParent({ type: 'REMOTE.REST' }) },
          },
        },
        [CHOOSE_NEXT]: {
          entry: timerActorActions.chooseNext,
          on: {
            CONTINUE: CONTINUE,
            STOP: FINISH,
          },
        },
        [START]: {
          invoke: {
            src: START,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimer],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
        [FINISH]: {
          invoke: {
            src: FINISH,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimer],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
        [CHECK]: {
          invoke: {
            src: CHECK,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimer],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
        [CONTINUE]: {
          invoke: {
            src: CONTINUE,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimer],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
        [SAVE_CONFIG]: {
          invoke: {
            src: SAVE_CONFIG,
            onDone: {
              target: CHOOSE_STATE,
              actions: [timerActorActions.assignTimerConfig],
            },
            onError: {
              target: ERROR,
              actions: timerActorActions.assignError,
            },
          },
        },
      },
    },
    { services }
  )
}

export const createTimerMachineWithActor = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actorMachine: StateMachine<TimerActorMachineContext, any, TimerActorEvent>
) => {
  return createMachine<TimerMachineContext, TimerEvent>(
    {
      id,
      predictableActionArguments: true,
      schema: {
        context: {} as TimerMachineContext,
        events: {} as TimerEvent,
      },
      context: {
        initiated: false,
      } as TimerMachineContext,
      initial: INITIATE,
      on: {
        SAVE_CONFIG: {
          actions: forwardTo((context) => context.actorMachine),
        },
        REINITIATE: {
          actions: forwardTo((context) => context.actorMachine),
        },
        SYNC: SYNC,
        'REMOTE.UPDATE_STATE': {
          actions: ['syncWithActor'],
        },
        UPDATE_NEXT_TASK_ID: {
          actions: forwardTo((context) => context.actorMachine),
        },
      },
      states: {
        [INITIATE]: {
          entry: [
            assign({
              bellRef: () => spawn(createSoundMachine(BELL_SOUND_ID)),
              tickRef: () => spawn(createSoundMachine(TICK_SOUND_ID)),
              actorMachine: () => spawn(actorMachine, { sync: true }),
            }),
          ],
          always: SYNC,
        },
        [SYNC]: {
          entry: [sendTo((context) => context.actorMachine, { type: 'SYNC' }), 'resetInitiated'],
          on: {
            'REMOTE.STOPPED': STOPPED,
            'REMOTE.WORKING': WORKING,
            'REMOTE.INTERVAL': INTERVAL,
            'REMOTE.REST': REST,
          },
        },
        [STOPPED]: {
          entry: ['setInitiated'],
          on: {
            START: {
              actions: forwardTo((context) => context.actorMachine),
            },
            'REMOTE.WORKING': WORKING,
            'REMOTE.INTERVAL': INTERVAL,
            'REMOTE.REST': REST,
          },
        },
        [WORKING]: {
          entry: [
            choose([
              {
                cond: (context, _event) => {
                  return context.initiated
                },
                actions: 'tickSound',
              },
              {
                actions: 'setInitiated',
              },
            ]),
          ],
          after: {
            END_TIME_DELAY: {
              actions: [sendTo((context) => context.actorMachine, { type: 'CHOOSE_NEXT' })],
            },
          },
          on: {
            STOP: {
              actions: forwardTo((context) => context.actorMachine),
            },
            CHECK: {
              actions: forwardTo((context) => context.actorMachine),
            },
            'REMOTE.STOPPED': STOPPED,
            'REMOTE.INTERVAL': INTERVAL,
            'REMOTE.REST': REST,
          },
        },
        [INTERVAL]: {
          entry: [
            choose([
              {
                cond: (context) => context.initiated,
                actions: 'bellSound',
              },
              {
                actions: 'setInitiated',
              },
            ]),
          ],
          after: {
            INTERVAL_TIME_DELAY: {
              actions: [sendTo((context) => context.actorMachine, { type: 'WORKING' })],
            },
          },
          on: {
            STOP: {
              actions: forwardTo((context) => context.actorMachine),
            },
            'REMOTE.STOPPED': STOPPED,
            'REMOTE.WORKING': WORKING,
            'REMOTE.REST': REST,
          },
        },
        [REST]: {
          entry: [
            choose([
              {
                cond: (context) => Boolean(context.initiated && context.timer?.is_finished),
                actions: 'bellSound',
              },
              {
                actions: 'setInitiated',
              },
            ]),
          ],
          after: {
            REST_TIME_WORK_DELAY: {
              cond: (context) => !context.timer?.is_finished,
              target: WORKING,
            },
            REST_TIME_STOP_DELAY: {
              cond: (context) => Boolean(context.timer?.is_finished),
              target: STOPPED,
            },
          },
          on: {
            STOP: {
              actions: forwardTo((context) => context.actorMachine),
            },
            CONTINUE: {
              actions: forwardTo((context) => context.actorMachine),
            },
            'REMOTE.REST': REST,
            'REMOTE.STOPPED': STOPPED,
            'REMOTE.WORKING': WORKING,
            'REMOTE.INTERVAL': INTERVAL,
          },
        },
        [ERROR]: {
          entry: log((context, event) => `error: ${context.error}, event: ${event}`, 'Finish label'),
          after: {
            10000: {
              actions: [sendTo((context) => context.actorMachine, { type: 'SYNC' }), 'resetInitiated'],
            },
          },
          on: {
            REINITIATE: INITIATE,
          },
        },
      },
    },
    {
      actions: {
        syncWithActor: assign({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          timer: (context) => context.actorMachine.getSnapshot()!.context.timer,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          timerConfig: (context) => context.actorMachine.getSnapshot()!.context.timerConfig,
        }),
        tickSound: sendTo((context) => context.tickRef, { type: 'RING' }),
        bellSound: sendTo((context) => context.bellRef, { type: 'RING' }),
        resetInitiated: assign({
          initiated: false,
        }),
        setInitiated: assign({
          initiated: true,
        }),
      },
      delays: {
        END_TIME_DELAY: (context) => {
          const timer = context.timer
          if (!timer) {
            return 60 * ONE_MINUTES_MS
          }
          return timer.end_time - Date.now()
        },
        INTERVAL_TIME_DELAY: (context) => {
          const timer = context.timer
          if (!timer) {
            return 60 * ONE_MINUTES_MS
          }
          return timer.start_time - Date.now()
        },
        REST_TIME_WORK_DELAY: (context) => {
          const timer = context.timer
          if (!timer || timer.is_finished) {
            return 60 * ONE_MINUTES_MS
          }
          return timer.start_time - Date.now()
        },
        REST_TIME_STOP_DELAY: (context) => {
          const timer = context.timer
          const config = context.timerConfig
          if (!timer || !timer.is_finished) {
            return 60 * ONE_MINUTES_MS
          }
          return timer.end_time + config.rest_minutes * ONE_MINUTES_MS - Date.now()
        },
      },
    }
  )
}
