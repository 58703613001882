import { useAuthenticator } from '@aws-amplify/ui-react'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'urql'
import {
  CreateTaskDocument,
  CreateTaskInput,
  DeleteTaskDocument,
  GenerateTaskQueueDocument,
  GetTaskDocument,
  SortTaskDocument,
  SortTaskInput,
  SortTaskQueueDocument,
  SortTaskQueueInput,
  UpdateTaskDocument,
  UpdateTaskInput,
} from '../generated'

export const useTask = () => {
  const { authStatus } = useAuthenticator((context) => [context.user])
  const [result, reexecuteQuery] = useQuery({ query: GetTaskDocument, pause: authStatus !== 'authenticated' })
  const [, createTaskMutation] = useMutation(CreateTaskDocument)
  const [, deleteTaskMutation] = useMutation(DeleteTaskDocument)
  const [, updateTaskMutation] = useMutation(UpdateTaskDocument)
  const [, generateTaskQueueMutation] = useMutation(GenerateTaskQueueDocument)
  const [, sortTaskMutation] = useMutation(SortTaskDocument)
  const [, sortTaskQueueMutation] = useMutation(SortTaskQueueDocument)
  const task = result.data?.task
  const tasks = task?.items ?? []

  const queue = useMemo(
    () =>
      task?.task_queue
        ? task.task_queue
            .map((qid) => task.items.find((t) => t.id === qid))
            .filter((o): o is NonNullable<typeof o> => Boolean(o))
        : [],
    [task]
  )

  const createTask = useCallback(
    async (input: CreateTaskInput) => {
      if (!task) {
        return
      }
      const result = await createTaskMutation({ input }, { additionalTypenames: ['Task'] })
      return result
    },
    [createTaskMutation, task]
  )

  const deleteTask = useCallback(
    async (id: string) => {
      if (!task) {
        return
      }
      const result = await deleteTaskMutation({ id }, { additionalTypenames: ['Task'] })
      return result
    },
    [deleteTaskMutation, task]
  )

  const updateTask = useCallback(
    async (input: UpdateTaskInput) => {
      if (!task) {
        return
      }
      const result = await updateTaskMutation({ input }, { additionalTypenames: ['Task'] })
      return result
    },
    [task, updateTaskMutation]
  )

  const sortTask = useCallback(
    async (input: SortTaskInput) => {
      if (!task) {
        return
      }
      const result = await sortTaskMutation({ input }, { additionalTypenames: ['Task'] })
      return result
    },
    [sortTaskMutation, task]
  )

  const sortTaskQueue = useCallback(
    async (input: SortTaskQueueInput) => {
      if (!task) {
        return
      }
      const result = await sortTaskQueueMutation({ input }, { additionalTypenames: ['Task'] })
      return result
    },
    [sortTaskQueueMutation, task]
  )

  const generateTaskQueue = useCallback(
    async (ids: string[]) => {
      if (!task) {
        return
      }
      const result = await generateTaskQueueMutation(
        {
          items: ids,
        },
        { additionalTypenames: ['Task'] }
      )
      return result
    },
    [generateTaskQueueMutation, task]
  )

  return {
    task,
    tasks,
    queue,
    createTask,
    deleteTask,
    updateTask,
    sortTask,
    sortTaskQueue,
    generateTaskQueue,
    reexecuteQuery,
  }
}
