import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../contexts/ThemeContext'

// Add or remove the "dark" class based on if the media query matches
const toggleDarkTheme = (shouldAdd: boolean) => {
  document.body.classList.toggle('dark', shouldAdd)
}

export const useTheme = () => {
  const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>()
  const { theme, updateTheme } = useContext(ThemeContext)

  /**
   * Set system color scheme change events
   */
  useEffect(() => {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
    setSystemTheme(theme !== 'light' && prefersDark.matches ? 'dark' : 'light')
    const toggleTheme = (mediaQuery: MediaQueryListEvent) => {
      if (theme) {
        return
      }
      toggleDarkTheme(mediaQuery.matches)
      setSystemTheme(mediaQuery.matches ? 'dark' : 'light')
    }
    toggleDarkTheme(theme ? theme === 'dark' : prefersDark.matches)
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', toggleTheme)
    return () => {
      prefersDark.removeEventListener('change', toggleTheme)
    }
  }, [theme])

  return {
    theme,
    systemTheme,
    updateTheme,
  }
}

export const useThemeBackground = () => {
  const theme = useContext(ThemeContext)
  const [color, setColor] = useState('')
  useEffect(() => {
    const bodyStyles = window.getComputedStyle(document.body)
    const _color = bodyStyles.getPropertyValue('--ion-background-color').trim() || '#ffffff'
    setColor(_color)
  }, [theme])

  return {
    color,
  }
}
