import { createContext, ReactNode, useContext, useEffect } from 'react'
import { signal, computed } from '@preact/signals-react'
import { TICK_INTERVAL } from '../consts/timer'
import set from 'date-fns/set'
import addSeconds from 'date-fns/addSeconds'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'

const initialTick = signal(0)
export const TickContext = createContext({ tick: initialTick, tickSec: computed(() => initialTick.value) })

export type TickProviderProps = {
  children: ReactNode
}

export const TickProvider = ({ children }: TickProviderProps) => {
  const tick = signal(Date.now())
  const tickSec = computed(() => {
    const _base = set(tick.value, { milliseconds: 0 }).getTime()
    const oneSecAhead = addSeconds(_base, 1).getTime()
    return differenceInMilliseconds(tick.value, oneSecAhead) < TICK_INTERVAL / 3 ? oneSecAhead : _base
  })

  return (
    <TickContext.Provider value={{ tick, tickSec }}>
      <UpdateTick />
      {children}
    </TickContext.Provider>
  )
}

const UpdateTick = () => {
  const { tick } = useContext(TickContext)

  useEffect(() => {
    const updateTick = () => {
      tick.value = Date.now()
    }
    const intervalId = window.setInterval(updateTick, TICK_INTERVAL)

    return () => {
      window.clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
