import { ReactNode, useContext, useMemo } from 'react'
import { ONE_MINUTES_MS } from '../../../consts/timer'
import { TickContext } from '../../../contexts/TickContext'
import { useTimerInfo } from '../../../hooks/useTimerMachine'
import { INTERVAL, REST, STOPPED, WORKING } from '../../../machines/timerStateKey'
import { Timer } from '../../../models/timer'

export type TickInfoRenderProps = {
  state?: typeof STOPPED | typeof WORKING | typeof INTERVAL | typeof REST | undefined
  nextSeconds: number
  end_point: number
  start_point: number
  tick: number
  timer?: Timer
}

export type TimerTickInfoProps = {
  children: (value: TickInfoRenderProps) => ReactNode
}

const getNextSec = (objective: number, tick: number) => {
  return Math.max((objective - tick) / 1000, 0)
}

export const TimerTickInfoState = ({ children }: TimerTickInfoProps) => {
  const { tickSec } = useContext(TickContext)
  const { state, timer, timerConfig } = useTimerInfo()
  const [stateValue, nextSeconds, start_point, end_point] = useMemo(() => {
    const start_time = timer?.start_time ?? 0
    const end_time = timer?.end_time ?? 0
    switch (state) {
      case STOPPED: {
        return [STOPPED, 0, 0, 0] as const
      }
      case WORKING: {
        return [WORKING, getNextSec(end_time, tickSec.value), start_time, end_time] as const
      }
      case INTERVAL: {
        return [INTERVAL, getNextSec(start_time, tickSec.value), start_time, end_time] as const
      }
      case REST: {
        if (timer?.is_finished) {
          const endRestTime = end_time + timerConfig.rest_minutes * ONE_MINUTES_MS
          return [REST, getNextSec(end_time, tickSec.value), endRestTime, 0] as const
        }
        return [REST, getNextSec(start_time, tickSec.value), start_time, end_time] as const
      }
      default:
        return [undefined, 0, 0, 0] as const
    }
  }, [state, tickSec.value, timer, timerConfig])

  return <>{children({ state: stateValue, nextSeconds, start_point, end_point, tick: tickSec.value, timer })}</>
}
