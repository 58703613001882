import { cacheExchange, createClient, fetchExchange } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { Auth } from 'aws-amplify'

export const client = createClient({
  url: process.env.REACT_APP_ENDPOINT_URL || '',
  exchanges: [
    requestPolicyExchange({
      ttl: 30 * 1000,
    }),
    cacheExchange,
    authExchange(async (utils) => {
      let session = await Auth.currentSession()
      return {
        addAuthToOperation(operation) {
          const token = session.getIdToken().getJwtToken()
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          })
        },
        didAuthError(error, _operation) {
          // message: "Unauthorized" comes from APIGW
          return error.message === 'Unauthorized' || error.graphQLErrors.some((e) => e.extensions?.code === 'Forbidden')
        },
        async refreshAuth() {
          const _session = await Auth.currentSession()
          if (_session.isValid()) {
            session = _session
          } else {
            throw new Error('cannot refreshAuth')
          }
        },
      }
    }),
    fetchExchange,
  ],
})
