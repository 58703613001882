import { assign, choose, createMachine, raise } from 'xstate'

export type SoundContext = {
  //
  elementId: string
  requestedAt: number
  rangAt: number
}

export type SoundEvent = { type: 'RING' } | { type: 'READY' } | { type: 'SELF' }

export const createSoundMachine = (elementId: string) =>
  createMachine<SoundContext, SoundEvent>({
    id: 'soundMachine',
    predictableActionArguments: true,
    context: {
      elementId,
      rangAt: 0,
    } as SoundContext,
    initial: 'waiting',
    on: {},
    states: {
      waiting: {
        after: [
          {
            delay: 1000,
            actions: choose([
              {
                cond: (context) => {
                  const dom = document.getElementById(context.elementId)
                  return Boolean(dom)
                },
                actions: raise('READY'),
              },
              {
                actions: raise('SELF'),
              },
            ]),
          },
        ],
        on: {
          READY: { target: 'ready' },
          SELF: 'waiting',
          RING: {
            actions: assign({
              requestedAt: () => Date.now(),
            }),
          },
        },
      },
      ready: {
        entry: choose([
          {
            cond: (context) => Boolean(context.requestedAt && Date.now() - 60 * 1000 < context.requestedAt),
            actions: raise('RING'),
          },
        ]),
        on: {
          RING: {
            target: 'ring',
            cond: (context) => Boolean(context.rangAt < Date.now() - 10 * 1000),
          },
        },
      },
      ring: {
        always: {
          target: 'ready',
          actions: [
            (context) => {
              const audioDom = document.getElementById(context.elementId)
              if (!audioDom || !(audioDom instanceof HTMLAudioElement)) return

              audioDom.play()
            },
            assign({
              requestedAt: 0,
              rangAt: () => Date.now(),
            }),
          ],
        },
      },
    },
  })
