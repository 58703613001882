import { nanoid } from 'nanoid'
import { DEFAULT_TIMER_CONFIG_NEW, INITIAL_REPS, ONE_MINUTES_MS } from '../consts/timer'
import { DeserializedID } from '../entity/ID'
import { Timer } from '../models/timer'
import { TimerConfig } from '../models/timerConfig'
import { storage, StorageEnum } from '../storage/default'
import { TimerActorEvent, TimerActorMachineContext } from './TimerActorTypes'
import { createTimerActorMachine, createTimerMachineWithActor, TimerActorMachineService } from './timerMachine'
import { CHECK, CONTINUE, FINISH, SAVE_CONFIG, START, SYNC } from './timerServiceKey'

export type DeserializedTimerConfigState = Omit<TimerConfig, 'id'> & {
  id: DeserializedID
}

export type DeserializedTimerState = Omit<Timer, 'id' | 'config_id' | 'prev_id'> & {
  id: DeserializedID
  config_id: DeserializedID
  prev_id?: DeserializedID
}

export const timerMapper = ({
  id,
  start_time,
  end_time,
  reps,
  config_id,
  prev_id,
  is_finished,
  is_valid,
  task,
}: DeserializedTimerState): Timer => ({
  id: id.id,
  config_id: config_id.id,
  prev_id: prev_id ? prev_id.id : undefined,
  reps,
  start_time,
  end_time,
  is_finished,
  is_valid,
  task,
})

const timerConfigMapper = ({
  id,
  focus_minutes,
  interval_minutes,
  reps,
  rest_minutes,
}: DeserializedTimerConfigState): TimerConfig => ({
  id: id.id,
  focus_minutes,
  interval_minutes,
  reps,
  rest_minutes,
})

const convertId = (id: string) => ({ _type: 'local', id })

const localTimerServices: TimerActorMachineService = {
  [SYNC]: async (_context: TimerActorMachineContext, _event: unknown) => {
    const timerInfo = await storage.get(StorageEnum.TIMER_INFO)
    if (timerInfo) {
      return timerInfo
    }

    const timer = await storage.get(StorageEnum.TIMER)
    const timerConfigStored = await storage.get(StorageEnum.TIMER_CONFIG)
    let timerConfig
    if (!timerConfigStored) {
      timerConfig = DEFAULT_TIMER_CONFIG_NEW
      await storage.set(StorageEnum.TIMER_CONFIG, { ...timerConfig, id: convertId(timerConfig.id) })
    } else {
      timerConfig = timerConfigMapper(timerConfigStored)
    }
    return {
      timer: timer ? timerMapper(timer) : undefined,
      timerConfig: timerConfig,
    }
  },
  [START]: async (context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'START') {
      throw new Error(`Invalid event type: ${event.type}`)
    }
    const config = context.timerConfig
    const timer = context.timer
    const startTime = Date.now()
    return {
      id: nanoid(),
      start_time: startTime,
      end_time: startTime + config.focus_minutes * ONE_MINUTES_MS,
      reps: INITIAL_REPS,
      config_id: config.id,
      prev_id: timer?.id,
      taskId: context.nextTaskId,
      is_finished: false,
      is_valid: false,
    }
  },
  [FINISH]: async (context: TimerActorMachineContext, _event: unknown) => {
    const prev = context.timer
    if (!prev || !prev?.is_valid) {
      return undefined
    }
    return {
      ...prev,
      end_time: Math.min(prev.end_time, Date.now()),
      is_finished: true,
    }
  },
  [CHECK]: async (context: TimerActorMachineContext, _event: unknown) => {
    const timer = context.timer
    if (!timer || timer.is_valid) {
      return timer
    }
    const now = Date.now()
    const halfPassed = timer.start_time + (timer.end_time - timer.start_time) / 2
    if (now < halfPassed) {
      return timer
    }
    return {
      ...timer,
      is_valid: true,
    }
  },
  [CONTINUE]: async (context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'CONTINUE') {
      throw new Error(`Invalid event type: ${event.type}`)
    }
    const config = context.timerConfig
    const timer = context.timer
    const tick = Date.now()
    if (!timer || !config) {
      return timer
    }
    // end_timeを超えていなければ今のtimerを継続
    if (tick < timer.end_time) {
      return timer
    }

    const isRepsOver = timer.reps >= config.reps
    const reps = isRepsOver ? INITIAL_REPS : timer.reps + 1
    const waitingMinutes = isRepsOver ? config.rest_minutes : config.interval_minutes
    const start_time = timer.end_time + waitingMinutes * ONE_MINUTES_MS
    const end_time = start_time + config.focus_minutes * ONE_MINUTES_MS
    return {
      id: nanoid(),
      start_time,
      end_time,
      reps,
      config_id: config.id,
      taskId: context.nextTaskId,
      is_finished: false,
      is_valid: false,
    }
  },
  [SAVE_CONFIG]: async (_context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'SAVE_CONFIG') {
      throw new Error(`Invalid event type: ${event.type}`)
    }

    return {
      id: nanoid(),
      ...event.timerConfig,
    }
  },
}

export const localTimerMachine = createTimerMachineWithActor(
  'localTimerMachine',
  createTimerActorMachine('localTimerActorMachine', localTimerServices)
)
