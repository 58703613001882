import { ReactNode } from 'react'
import { useTimerInfo } from '../../../hooks/useTimerMachine'
import { Timer } from '../../../models/timer'
import { TimerConfig } from '../../../models/timerConfig'

export type TimerInfoStateRenderProps = {
  timer?: Timer
  timerConfig: TimerConfig
}

export type TimerInfoStateProps = {
  children: (value: TimerInfoStateRenderProps) => ReactNode
}

export const TimerInfoState = ({ children }: TimerInfoStateProps) => {
  const { timer, timerConfig } = useTimerInfo()

  return <>{children({ timer, timerConfig })}</>
}
