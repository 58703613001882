import { INITIAL_REPS, ONE_MINUTES_MS } from '../consts/timer'
import {
  CheckTimerMutationDocument,
  CreateTimerMutationDocument,
  FinishTimerMutationDocument,
  GetTimerAndConfigDocument,
  MyTimerConfigMutationDocument,
  Timer as ServerTimer,
} from '../generated'
import { Timer } from '../models/timer'
import { handleError } from '../utils/handle-error'
import { getTimeByISO, toISOFromTimestamp } from '../utils/timer'
import { client } from '../utils/urql'
import { TimerActorEvent, TimerActorMachineContext } from './TimerActorTypes'
import { createTimerActorMachine, createTimerMachineWithActor, TimerActorMachineService } from './timerMachine'
import { CHECK, CONTINUE, FINISH, SAVE_CONFIG, START, SYNC } from './timerServiceKey'

const mapTimer = (timer?: ServerTimer | null): Timer | undefined =>
  timer
    ? {
        id: timer.id,
        start_time: getTimeByISO(timer.start_time),
        end_time: getTimeByISO(timer.end_time),
        reps: timer.reps,
        config_id: timer.config_id,
        prev_id: timer.prev_id ?? undefined,
        is_finished: timer.is_finished,
        is_valid: timer.is_valid,
        task: timer.task ?? undefined,
      }
    : undefined

const syncTimerServices: TimerActorMachineService = {
  [SYNC]: async (_context: TimerActorMachineContext, _event: unknown) => {
    const { data, error } = await client
      .query(GetTimerAndConfigDocument, {}, { requestPolicy: 'network-only' })
      .toPromise()

    if (error) {
      throw handleError(error)
    }
    if (!data) {
      throw new Error('data not exists')
    }
    return {
      timer: mapTimer(data.timer),
      timerConfig: data.timerConfig,
    }
  },
  [START]: async (context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'START') {
      throw new Error(`Invalid event type: ${event.type}`)
    }
    const config = context.timerConfig
    const timer = context.timer
    const startTime = Date.now()
    const nextTaskId = context.nextTaskId
    const { data, error } = await client
      .mutation(
        CreateTimerMutationDocument,
        {
          input: {
            config_id: config.id,
            start_time: toISOFromTimestamp(startTime),
            end_time: toISOFromTimestamp(startTime + config.focus_minutes * ONE_MINUTES_MS),
            prev_id: timer?.id,
            reps: INITIAL_REPS,
            task_id: nextTaskId,
          },
        },
        { additionalTypenames: ['Task'] }
      )
      .toPromise()
    if (error) {
      throw handleError(error)
    }
    if (!data) {
      throw new Error('data not exists')
    }
    return mapTimer(data.createTimer)
  },
  [FINISH]: async (context: TimerActorMachineContext, _event: unknown) => {
    const timer = context.timer
    if (!timer) {
      return undefined
    }
    const { data, error } = await client
      .mutation(FinishTimerMutationDocument, { input: timer.id }, { additionalTypenames: ['Task'] })
      .toPromise()

    if (error) {
      throw handleError(error)
    }
    if (!data) {
      throw new Error('data not exists')
    }
    return {
      ...timer,
      is_finished: true,
    }
  },
  [CHECK]: async (context: TimerActorMachineContext, _event: unknown) => {
    const timer = context.timer
    if (!timer || timer.is_valid) {
      return timer
    }
    const timerId = timer.id
    const now = Date.now()
    const halfPassed = timer.start_time + (timer.end_time - timer.start_time) / 2
    if (now < halfPassed) {
      return timer
    }
    const { data, error } = await client
      .mutation(CheckTimerMutationDocument, {
        input: timerId,
      })
      .toPromise()
    if (error) {
      throw handleError(error)
    } else if (!data) {
      throw new Error('data not exists')
    }
    return mapTimer(data.checkTimer)
  },
  [CONTINUE]: async (context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'CONTINUE') {
      throw new Error(`Invalid event type: ${event.type}`)
    }
    const config = context.timerConfig
    const timer = context.timer
    const tick = Date.now()
    if (!timer || !config) {
      return timer
    }
    // end_timeを超えていなければ今のtimerを継続
    if (tick < timer.end_time) {
      return timer
    }

    const nextTaskId = context.nextTaskId
    const isRepsOver = timer.reps >= config.reps
    const reps = isRepsOver ? INITIAL_REPS : timer.reps + 1
    const waitingMinutes = isRepsOver ? config.rest_minutes : config.interval_minutes
    const start_time = timer.end_time + waitingMinutes * ONE_MINUTES_MS
    const end_time = start_time + config.focus_minutes * ONE_MINUTES_MS
    const { error, data } = await client
      .mutation(
        CreateTimerMutationDocument,
        {
          input: {
            config_id: config.id,
            start_time: toISOFromTimestamp(start_time),
            end_time: toISOFromTimestamp(end_time),
            prev_id: timer?.id,
            reps,
            task_id: nextTaskId,
          },
        },
        { additionalTypenames: ['Task'] }
      )
      .toPromise()
    if (error) {
      throw handleError(error)
    }
    if (!data) {
      throw new Error('data not exists')
    }
    return mapTimer(data.createTimer)
  },
  [SAVE_CONFIG]: async (_context: TimerActorMachineContext, event: TimerActorEvent) => {
    if (event.type !== 'SAVE_CONFIG') {
      throw new Error(`Invalid event type: ${event.type}`)
    }
    const config = event.timerConfig
    const { data, error } = await client
      .mutation(MyTimerConfigMutationDocument, {
        input: {
          focus_minutes: config.focus_minutes,
          interval_minutes: config.interval_minutes,
          rest_minutes: config.rest_minutes,
          reps: config.reps,
        },
      })
      .toPromise()
    if (error) {
      throw handleError(error)
    } else if (!data) {
      throw new Error('data not exists')
    }
    return data.createTimerConfig
  },
}

export const syncTimerMachine = createTimerMachineWithActor(
  'syncTimerMachine',
  createTimerActorMachine('syncTimerActorMachine', syncTimerServices)
)
