import { Auth } from 'aws-amplify'
import { useCallback } from 'react'
import { useMutation } from 'urql'
import { DeleteUserMutationDocument } from '../generated'
import { useStorage } from './useStorage'

export const useAccountDelete = () => {
  const { clearStorage } = useStorage()
  const [, deleteUserMutation] = useMutation(DeleteUserMutationDocument)

  const accountDelete = useCallback(async () => {
    await clearStorage()
    await deleteUserMutation({})
    await Auth.deleteUser()
  }, [clearStorage, deleteUserMutation])

  return { accountDelete }
}
