import clsx from 'clsx'
import { Suspense } from 'react'
import { TaskQueueAction } from '../../components/organisms/action/TaskQueueAction'
import { TimerActions } from '../../components/organisms/action/TimerActions'
import { PictureInPicture } from '../../components/organisms/PictureInPicture'
import { TimerInfoState } from '../../components/organisms/utils/TimerInfoState'
import TimerClock from '../../components/organisms/TimerClock'
import { HOME_FLOAT_ACTION_ID } from '../../consts/dom'
import { TimerInfo } from '../../components/organisms/timer/TimerInfo'

export const Home = () => {
  return (
    <div className="container flex flex-col justify-center items-center mx-auto h-full">
      <Suspense fallback={<></>}>
        <TimerInfoState>
          {({ timer }) => {
            let style = undefined
            let isWorking = false
            if (timer && !timer.is_finished) {
              isWorking = true
              if (timer?.task) {
                style = { backgroundColor: timer.task.color_code }
              }
            }
            return (
              <div className="overflow-hidden absolute inset-0 -z-50">
                <div
                  className={clsx(
                    'absolute -top-32 -right-32 w-48 h-48 rounded-full transition-transform duration-700',
                    isWorking && 'scale-[30]'
                  )}
                  style={style}
                ></div>
                {timer?.task && (
                  <div
                    className={clsx(
                      'grid absolute inset-x-0 top-0 place-content-center h-20 text-lg font-semibold',
                      timer.task && timer.task.color_name !== 'transparent'
                        ? 'text-white'
                        : 'text-black dark:text-white'
                    )}
                  >
                    <p>{timer.task.task_name}</p>
                  </div>
                )}
              </div>
            )
          }}
        </TimerInfoState>
      </Suspense>
      <Suspense fallback={<></>}>
        <TimerInfo />
        <div className="grid place-items-center">
          <PictureInPicture>
            <div className="p-2 sm:p-0 sm:w-80 sm:h-80 md:w-96 md:h-96">
              <TimerClock />
            </div>
          </PictureInPicture>
        </div>
        <div className="mt-4 sm:mt-8">
          <TimerActions />
        </div>
        <div id={HOME_FLOAT_ACTION_ID} className="flex fixed right-0 bottom-0 z-10 flex-col m-2"></div>
        <div className="overflow-hidden absolute inset-y-0 right-0 z-0 w-12">
          <TaskQueueAction />
        </div>
      </Suspense>
    </div>
  )
}

export default Home
