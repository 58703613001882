import { nanoid } from 'nanoid'
import { LocalID } from '../entity/ID'

export const DEFAULT_TIMER_CONFIG = {
  id: new LocalID(),
  focus_minutes: 25,
  interval_minutes: 5,
  reps: 4,
  rest_minutes: 20,
}

export const TICK_INTERVAL = 180

export const DEFAULT_TIMER_CONFIG_NEW = {
  id: nanoid(),
  focus_minutes: 25,
  interval_minutes: 5,
  reps: 4,
  rest_minutes: 20,
}

export const ONE_MINUTES_MS = 60 * 1000
export const INITIAL_REPS = 1
